<template>
  <div id="GedNovoDocumentosModal">
    <v-card 
      v-bind:style="{ 'background-color':COR_SECUNDARIA }"
      class="container pa-0">

      <!-- Titulo -->
      <v-card-title 
        v-bind:style="{ 'background-color':COR_PRINCIPAL }"
        class="justify-space-between pa-2">
        <span class="text-white title-page body-1">Novo Documento</span>
        <v-btn
          @click="$emit('fecharModal');$emit('fecharModalPagar')"
          icon
          dark
          color="#F2F6F7">
          <v-icon large class="title">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
            
      <!-- Corpo -->
      <v-card-text class="wrapper pt-1 px-0 pb-13">
        <v-row>
          <v-col cols="12">
            <v-container class="px-7">
              <v-form
                v-model="valid"
                ref="form">
                <v-row class="mb-n5">
                  <v-col cols="8"
                    class="pb-0 px-0">
                    <v-autocomplete 
                      :key="key_TipoDocumento"
                      label="Tipo de Documento" 
                      v-model="dados_tipo.tipo_documento" 
                      filled 
                      light 
                      outlined
                      dense 
                      return-object
                      background-color="#FFF"
                      item-text = "tipo_documento"
                      item-value = "tipo_documento"
                      :items="arrayTipoDocumento" 
                      :rules="rulesTipoDocumento"
                      :disabled="tipo_sistema ? true : false" />
                  </v-col>
                  <v-col cols="4">
                    <v-select 
                      label="Público" 
                      v-model="dados_tipo.publico" 
                      :items="array_sim_nao"
                      filled 
                      light 
                      outlined
                      dense 
                      background-color="#FFF" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0 pl-0" cols="12">
                    <v-text-field
                      dense
                      v-model="dados_tipo.titulo"
                      cache-items
                      flat
                      outlined
                      label="Título"
                      placeholder="Título"
                      filled
                      background-color="#FFF"
                      clearable
                      maxlength="100"
                      tabindex="0" />
                  </v-col>

                  <!-- <DatePicker 
                    label="Data do Vencimento" 
                    placeholder="dia/mês/ano" 
                    outlined 
                    v-if="vencimento_visivel"
                    v-model="validadeAux" 
                    backgroundColor="#FFF"  
                    tabindex="0"
                    dense 
                    format="DD/MM/YYYY"
                    :rules="rulesVencimentoDocumento"
                  ></DatePicker> -->
                  
                  <v-col cols="12" class="pl-0 pt-0 ml-0">
                    <v-file-input 
                      v-model="image" 
                      @change="readURL" 
                      prepend-icon="mdi-camera" 
                      background-color="#FFF" 
                      small-chips 
                      outlined
                      filled 
                      light 
                      dense 
                      :rules="rulesDocumentos" 
                      class="mt-n2" label="Enviar Documento">
                    </v-file-input>
                  </v-col>
                </v-row>
                <v-row v-if="resizedImg" class="mt-n5 mb-0" >
                  <v-card
                    elevation="0"
                    style=" vertical-align:middle;
                            text-align:center;
                            width: 100%;
                            height: 100%;
                            background-color: transparent">
                    <div 
                      class="align-center fill-height" justify="center"
                      style=" vertical-align:middle;
                              text-align:center;
                              max-width: 100%;
                              max-height: 100%;
                              background-color: transparent;">
                              <img 
                        :src="resizedImg"
                        style="max-width: 160px;
                        max-height: 222px;
                        border: 1px solid silver;
                        background-color: #FFFFFF; 
                        height: auto;
                        width: auto">
                    </div>
                  </v-card>
                </v-row>
              </v-form>
            </v-container>
          </v-col>
          <!-- <v-col cols="5">
            teste
          </v-col> -->
        </v-row>

        <v-divider class="divisor mx-4 mb-2"></v-divider>

      </v-card-text>

      <v-footer
        :style="{ 'background-color':COR_SECUNDARIA }"
        absolute
        elevation="0"
        class="d-flex justify-center pa-3">
        <v-btn
          @click="$emit('fecharModal');$emit('fecharModalPagar')"
          class="mr-4 btn caption font-weight-medium"
          color="primary"
          text>
          Cancelar
        </v-btn>

        <v-btn
          :disabled="!valid"
          :loading="loading_salvar"
          @click="validate()"
          class="btn white--text caption font-weight-medium"
          color="primary accent-4">
          Salvar
        </v-btn>
      </v-footer>
    </v-card>
  </div>
</template>

<script>
import store_Ged from "./store_Ged"
import store_site from "../../store/store_site"
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SUBTITULO } from "../../services/constantes";
import {formatDateTime, resizeImage, blobToBase64, groupArrayMultKeys, generatePDFThumbnails } from "../../services/funcoes";
import { baseURL, API } from "../../services/API";
import moment from 'moment'

export default {
  name: "GedNovoDocumentosModal",

  props: [
    "cod_empreendcobranca_aux", 
    "dados_documentos", 
    "paramsTipoDocumento",
    'cod_pessoa_aux',
    'cod_empreendvenda_aux',
    'cod_empreendimento_aux',
    'cod_obra_aux',
    'cod_imovel_aux',
    'statusDocumento',
    'tipo_sistema',
  ],

  components: {
  },

  data() {
    return {
      store_Ged         : store_Ged,
      store_site        : store_site,

      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SUBTITULO     : COR_SUBTITULO,
      COR_SECUNDARIA    : COR_SECUNDARIA,

      key_TipoDocumento   : 1,
      filtro              : null,
      panel               : [],
      tamanhoArray        : [],
      // search             : null,
      // search_input_focus : false,
      arrayTipoDocumento : null,
      vencimento_visivel : null,
      image              : null,
      loading_salvar     : false,
      loading_excluir    : false,
      resizedImg         : null,
      tipo_documento_aux : null,
      tipo_Aux           : null,
      cadastro_dt_Aux    : null,
      dialog_excluir     : false,
      dialog_ver_imagem_documento : null,
      tipo_arquivo_aux            : null,
      dados_tipo: {
        tipo: "",
        titulo: null,
        publico: 'NÃO',
        documento: null,
      },

      array_sim_nao: [
        "SIM", "NÃO",
      ],

      /* Formulário */
      valid: false,

      dialogNovoDocumento: false,

      dados: { 
        tipo: "",
        documento: null,
      },

      rulesDocumentos: [
        (value) => !!value || "O documento é obrigatório",
      ],

      rulesTipoDocumento: [
        (value) => !!value || "O tipo de documento é obrigatório",
      ],

      rulesVencimentoDocumento: [
        (value) => !!value || "A data do vencimento é obrigatório",
      ],
    };
  },

  async mounted() {
    let parametros = {...this.paramsTipoDocumento}
    await this.gedTipoDoc(parametros)

    // se vier de cobranca, deixar o modal com tipo de documento e titulo setado
    if(parametros.tipo_cadastro === 'cobranca'){
      let lo_tipo_doc = this.arrayTipoDocumento.find(val => val.tipo_documento === 'Comprovante')
      this.dados_tipo.tipo_documento = lo_tipo_doc
      this.dados_tipo.titulo = 'Comprovante'
    }
    if(this.tipo_sistema) {
      let lo_tipo_doc = this.arrayTipoDocumento.find(val => val.tipo_sistema === this.tipo_sistema)
      this.dados_tipo.tipo_documento = lo_tipo_doc;      
      this.key_TipoDocumento++;
    }
  },
  
  methods: {
    fechar(){
      this.$emit("fecharModal", false);
    },

    async gedTipoDoc(p_params){
      if (this.tipo_sistema)
        p_params.tipo_sistema = this.tipo_sistema;
      const {data} = await API.get(`ged_tipo_documento`, { params: {...p_params}});
      this.arrayTipoDocumento = data.result === 'Não possui dados' ? [] : data.result;
    },

    readURL(file) {
      // START: preview original
      // you can remove this section if you don't like to preview original image

      const reader = new FileReader();
      reader.onload = async (e) => {
        this.originalImg = e.target.result;
        //console.log("originalImg:::::: ", this.originalImg)

        if (file.type.match(/pdf.*/)) {
          const array_thumbs = await generatePDFThumbnails(this.originalImg, 150, 1);
          if (array_thumbs) {
            this.resizedImg = array_thumbs[0].thumbnail;
            //console.log("this.resizedImg 1::::", this.resizedImg)
          }
        }
        else if (file.type.match(/image.*/)) {
          // START: preview resized
          resizeImage({
            file: file,
            maxSize: 170
          }).then(async (resizedImage) => {
            this.resizedImg = await blobToBase64(resizedImage);

            //this.resizedImg = URL.createObjectURL(resizedImage);
            //console.log("this.resizedImg 2::::", this.resizedImg)
          }).catch((err) => {
            console.error(err);
          });
          // END: preview resized
        }

      };
      reader.readAsDataURL(file); // convert to base64 string
      // END: preview original
    },

    url() {
      if (!this.image)
        return;

      if (imagem.type)
      return URL.createObjectURL(this.image);
    },

    async validate() {
      this.loading_salvar = true;

      let ls_Res;
      const lb_valido = this.$refs.form.validate();

      if (lb_valido) {
        let ls_tipo = this.image.name.split(".")
        
        if (this.originalImg) {
          var ls_document_original = this.originalImg.split("base64,");
          ls_document_original = ls_document_original[ls_document_original.length - 1]
        }
       
        if (this.resizedImg) {
          var ls_document_thumb = this.resizedImg.split("base64,");
          ls_document_thumb = ls_document_thumb[ls_document_thumb.length - 1]
        } else {
          ls_document_thumb == null
        }

        if(!this.validadeAux){
          this.validadeAux = moment('2900-01-01').format()
        }
        else{
          this.validadeAux = moment(this.validadeAux,'DD/MM/YYYY').format()

        }
        var lo_JSON = {
          cod_ged_tipo_doc            : this.dados_tipo.tipo_documento.cod_ged_tipo_doc,
          cod_empreendcobranca        : this.cod_empreendcobranca_aux || null,
          cod_pessoa                  : this.cod_pessoa_aux || null,
          cod_empreendvenda           : this.cod_empreendvenda_aux || null,
          cod_empreendimento          : this.cod_empreendimento_aux || null,
          cod_obra                    : this.cod_obra_aux || null,
          cod_imovel                  : this.cod_imovel_aux || null,
          tipo_documento              : this.dados_tipo.tipo_documento.tipo_documento,
          titulo                      : this.dados_tipo.titulo || this.dados_tipo.tipo_documento.tipo_documento,
          publico                     : this.dados_tipo.publico,
          tipo_arquivo                : ls_tipo[ls_tipo.length - 1],
          documento_base64            : ls_document_original,
          documento_miniatura_base64  : ls_document_thumb,
          validade                    : this.validadeAux,
          tipo_cadastro               : this.paramsTipoDocumento.tipo_cadastro,
          situacao                    : this.statusDocumento,
          arquivo_descricao           : this.image.name
        };
        //console.log("🚀 ~ file: Documentos.vue:743 ~ validate ~ lo_JSON:", lo_JSON)
        // return
       
        ls_Res = await this.store_Ged.postGed(lo_JSON);

        this.fechar()
        this.$emit('fecharModalPagar')

        if (ls_Res.message == "success") {
          this.store_site.alert_cor = "green";
          this.store_site.alert_type = "success";
          this.store_site.alert_timeout = 1500;
          this.store_site.alert_msg = ls_Res.result;
          this.store_site.alert = true;

          this.$emit('buscaDocumentos', true)
        } else {
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_cor = "red";
          this.store_site.alert_type = "warning";
          this.store_site.alert_msg = ls_Res.errors;
          this.store_site.alert = true;
        }
      }
    },

  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },
};
</script>

<style scoped>
#GedDocumentos {
  height: calc(100vh - 100px);
  /* width: 100%; */
  /* padding: 10px; */
  /* padding-top: 0; */
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--COR_SECUNDARIA);
}

/* ---------- BARRA DE SCROLL ---------- */
#GedDocumentos::-webkit-scrollbar {
  width: 5px;
}

.v-dialog > * {
  overflow: hidden;
}

#GedDocumentos::-webkit-scrollbar-button {
  padding: 1px;
}

#GedDocumentos::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#GedDocumentos::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 100px;
} 

.title-page {
  font-family: "Open Sans", sans-serif!important;
  letter-spacing: 0px;
  font-size: 18px;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.btn {
  width: 100px;
}

.search-input, .search-input-longo {
  margin: 0 3% 1.2rem 0;
  height: 35px !important;
  max-width: 400px!important;
  min-width: 400px!important;
}

.container {
    max-width: initial;
}

@media(max-width: 1024px) {
 .search-input, .search-input-longo {
    max-width: 250px!important;
    min-width: 250px!important;
  }
}

@media (max-width: 768px) {
  .headline {
    flex-direction: column;
  }
  .container_btn-header {
    margin-left: 0px!important;
  }

  .search-input {
    margin: 0.8rem 3% 1.2rem 0;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    /* min-width: calc(100% - 15px)!important; */
    /* max-width: calc(100% - 15px)!important; */
  }
}

@media(max-width: 599px) {
  .search-input {
    margin: 0.8rem 20px 1.2rem 0;
    min-width: auto!important;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    /* min-width: calc(100% - 15px)!important; */
  }

  .container_btn-header .caption {
    margin-top: 16px!important;
  }
}
  
.select-order {
  max-width: 210px;
}

@media(max-width: 768px) {
  .container_btn-header .caption {
    margin-top: 12px!important;
  }

  .select-order {
    margin-top: 12px!important;
    margin-right: 0px!important;
  }
}

@media(max-width: 599px) {
  .select-order-desktop {
    display: none;
  }

  .select-order {
    max-width: 50px;
  }

  .container_btn-header .caption {
    margin-top: 16px!important;
  }
}

.col-wrapper {
  max-width: 196px;
}

@media(max-width: 1024px) {
  .col-wrapper {
     max-width: 30%!important;
  }
}

@media(max-width: 768px) {
  .col-wrapper {
     max-width: 45%!important;
  }
}

@media(max-width: 599px) {
  .col-wrapper {
     max-width: 46%!important;
  }
}

.col-wrapper .card:last-child {
  margin-right: 0px!important;
}

.col-wrapper {
  position: relative;
}

.card-image {
  height: 250px;
}

@media(max-width: 599px) {
  .card-image {
    height: 150px;
  }
}

.document-name {
  max-width: 20ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 3;
}

@media(max-width: 1024px) {
  .document-name {
    max-width: 15ch;
  } 
}

@media(max-width: 599px) {
  .document-name {
    max-width: 8ch;
  } 
}

@media(max-width: 375px) {
  .document-name {
    max-width: 6ch;
  } 
}

@media(max-width: 360px) {
  .document-name {
    max-width: 5ch;
  } 
}

.container_btn-icon {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0!important;
  visibility: hidden!important;
  -webkit-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  background: rgba(25, 118, 210, 0.2); 
}

.col-wrapper:hover .container_btn-icon {
  visibility: visible!important;
  opacity: 1!important;
}

.v-dialog {
  overflow: hidden;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
  font-size: 19px!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.title-page-menor {
  font-family: "Open Sans", sans-serif!important;
  font-size: 16px!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.status_ativo {
  font-size: 14px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_pendente {
  font-size: 14px !important;
  background: goldenrod;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_cancelado {
  font-size: 14px !important;
  background: #ff5252;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}
.btn-icon {
  width: 45px;
  height: 45px;
  background: rgb(255, 255, 255, 0.7);
  transition: 0.3s;
}

@media(max-width: 599px) {
  .btn-icon {
    width: 30px;
    height: 30px;
  }
}

.v-divider {
  background: var(--COR_PRINCIPAL)!important;
}
.btn-icon:hover {
  background: #FFF;
}

.icon-action {
  font-size: 30px!important;
}

@media(max-width: 599px) {
  .icon-action {
    font-size: 20px!important;
  }
}

.divisor {
  height: 1px;
  border-color: #C0C0C0;
}

</style>